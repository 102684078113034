import { Paddix } from "@paddix/paddix-core";
import multiTenantPlugin from "@paddix/paddix-multi-tenant-plugin";
import contentPlugin from "@paddix/paddix-content-plugin";
import imagePlugin from "@paddix/paddix-image-plugin";
import sitePlugin from "@paddix/paddix-site-plugin";
import newsletterPlugin from "@paddix/paddix-newsletter-plugin";
import publishingPlugin from "@paddix/paddix-publishing-plugin";
import documentPlugin from "@paddix/paddix-document-plugin";
import snacDataPlugin from "@paddix/paddix-snac-data-plugin";
import edaCrac2021Plugin from "@paddix/paddix-eda-crac-2021-plugin";

Paddix.use(multiTenantPlugin)
  .use(contentPlugin)
  .use(imagePlugin)
  .use(sitePlugin)
  .use(newsletterPlugin)
  .use(publishingPlugin)
  .use(snacDataPlugin)
  .use(documentPlugin)
  .use(edaCrac2021Plugin)
  .start();
